import { Project } from "../entity/project.entity"

export const PROJECTS_LIST = {
    midgo: "midgo",
    momen: "momen",
    ativa: "ativa",
    épiqe: "épiqe",
    muvin: "muvin",
    nesta: "nesta",
    sente: "sente",
    duplo: "duplo",
    velia: "velia",
    prana: "prana",
}

export const SPERANT_ENV = {
    cosapi: "cosapi",
    cosapilar: "cosapilar"
}

export const CRM_MEDIUMS = {
    [SPERANT_ENV.cosapi]: {
        source_ids: {
            web: 45, // cosapi_página web
            colaborator: 11,
            referidos: 49,
            multiproducto: 91
        },
        input_channels: {
            web: 4, // contacto web
        }
    },
    [SPERANT_ENV.cosapilar]: {
        source_ids: {
            // web: 47, // web cosapi inmobiliaria
            // colaborator: 11,
            // referidos: 16,
            // multiproducto: 91
            web: 45, // cosapi_página web
            colaborator: 11,
            referidos: 49,
            multiproducto: 91
        },
        input_channels: {
            // web: 10, // web cosapi inmobiliaria
            web: 4, // contacto web
        }
    },
}

export const PROJECTS_CRM = {
    [PROJECTS_LIST.midgo]: {id: 27, lead_creation_allowed: true, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.momen]: {id: 26, lead_creation_allowed: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.épiqe]: {id: 14, lead_creation_allowed: true, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.muvin]: {id: 19, lead_creation_allowed: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.sente]: {id: 23, lead_creation_allowed: true, environment: SPERANT_ENV.cosapilar, template_id: 73, ...CRM_MEDIUMS.cosapilar},
    [PROJECTS_LIST.nesta]: {id: 24, lead_creation_allowed: true, environment: SPERANT_ENV.cosapilar, template_id: 74, ...CRM_MEDIUMS.cosapilar},
    [PROJECTS_LIST.ativa]: {id: 20, lead_creation_allowed: true, environment: SPERANT_ENV.cosapi, template_id: 58, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.prana]: {id: null, lead_creation_allowed: false, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.velia]: {id: null, lead_creation_allowed: false, environment: SPERANT_ENV.cosapi, template_id: null, ...CRM_MEDIUMS.cosapi},
    [PROJECTS_LIST.duplo]: {id: null, lead_creation_allowed: false, environment: SPERANT_ENV.cosapilar, template_id: null, ...CRM_MEDIUMS.cosapilar},
}

const ucFirst = string => string.charAt(0).toUpperCase() + string.slice(1)

export const PROJECT_LOCATIONS = {
    [PROJECTS_LIST.midgo]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Arequipa 1890", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS_LIST.momen]: {department: "Lima", province: "Lima", district: "Breña", address: "Av. Aguarico 1251", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS_LIST.ativa]: {department: "Lima", province: "Lima", district: "Surco", address: "Av. El Derby 160", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS_LIST.épiqe]: {department: "Lima", province: "Lima", district: "Surco", address: "Av. Manuel Olguín 600", latitud: -12.0855536, longitud: -76.9733498},
    [PROJECTS_LIST.muvin]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Prolongación Iquitos 1870", latitud: -12.080832798297171, longitud: -77.02868410158379},
    [PROJECTS_LIST.sente]: {department: "Lima", province: "Lima", district: "Breña", address: "Jr. Centenario 175", latitud: -11.49638185, longitud: -77.2098442965157},
    [PROJECTS_LIST.nesta]: {department: "Lima", province: "Lima", district: "Jesús María", address: "Av. Salaverry 475", latitud: -12.08694, longitud: -77.050018},
    [PROJECTS_LIST.duplo]: {department: "Lima", province: "Lima", district: "Breña", address: "Av. Brasil 840", latitud: -12.067735650505, longitud: -77.0474958343434},
    [PROJECTS_LIST.prana]: {department: "Lima", province: "Lima", district: "Pueblo Libre", address: "Av. Sucre 132", latitud: -12.0967589, longitud: -77.0724546},
    [PROJECTS_LIST.velia]: {department: "Lima", province: "Lima", district: "Lince", address: "Av. Belisario 1035", latitud: -12.0832011, longitud: -77.0454049},
} 

export const PROJECT_SELLERS = {
    [PROJECTS_LIST.épiqe]: [],
    [PROJECTS_LIST.muvin]: [],
    [PROJECTS_LIST.nesta]: [],
    [PROJECTS_LIST.sente]: [],
}

export const PROJECT_PHASES = {
  [PROJECTS_LIST.midgo]: [
        { 
            id: 29, name: "", stage: "Preventa",
            dorms:[
                {value:"2",text:"2 dorms. desde S/2,950 al mes*"},
                {value:"3",text:"2 dorms. + sala de estar desde S/3,850 al mes*"},
            ]
        },
  ],
  [PROJECTS_LIST.momen]: [
        { 
            id: 22, name: "", stage: "Preventa",
            dorms:[
                {value:"2",text:"2 dorms. desde S/2,200 al mes*"},
                {value:"3",text:"3 dorms. desde S/2,600 al mes*"},
            ]
        },
  ],
  [PROJECTS_LIST.ativa]: [
    { 
        id: 20, name: "Fase 1", stage: "En Construcción",
        dorms:[
            {value:"1",text:"1 dorm. desde S/ 3, 700 al mes*"},
            {value:"2",text:"2 dorms. desde S/4,100 al mes*"},
            {value:"3",text:"3 dorms. desde S/5,500 al mes*"},
        ]
    },
    { 
        id: 22, name: "Fase 2", stage: "En Construcción",
        dorms:[
            {value:"2",text:"2 dorms. desde S/4,200 al mes*"},
            {value:"3",text:"3 dorms. desde S/5,900 al mes*"},
        ]
    },
],
  [PROJECTS_LIST.épiqe]: [
    { 
        id: 14, name: "", stage: "Entrega Inmediata",
        dorms:[
            {value:"2",text:"2 Dorms. desde S/4,500 al mes*"},
            {value:"3",text:"3 Dorms. desde S/6,100 al mes*"},
        ]
    },
  ],
  [PROJECTS_LIST.muvin]: [
        { 
            name: "", stage: "Entrega Inmediata",
            dorms:[
                {value:"2",text:"2 dorms. desde S/3,600 al mes*"},
                {value:"3",text:"3 dorms. desde S/4,600 al mes*"},
            ]
        }
    ],
    [PROJECTS_LIST.nesta]:[
        {
            name: "Fase 4", stage: "Entrega Inmediata",
            dorms:[
                {value:"2",text:"2 dorms. desde S/4,300 al mes*"},
                {value:"3",text:"3 dorms. desde S/4,810 al mes*"},
            ]
        },
        { 
            name: "Fase 5", stage: "Entrega Inmediata",
            dorms:[
                {value:"2",text:"2 dorms. desde S/4,300 al mes*"},
                {value:"3",text:"3 dorms. desde S/4,810 al mes*"},
            ]
        },
    ],
    [PROJECTS_LIST.sente]: [
        {
            name: "", stage: "Entrega Inmediata",
            dorms:[
                {value:"2",text:"2 Dorms. desde S/2,850 al mes*"},
                {value:"3",text:"3 Dorms. desde S/3,320 al mes*"},
            ]
        }
    ],
    [PROJECTS_LIST.duplo]: [{ name: "", stage: "Vendido" }],
    [PROJECTS_LIST.prana]: [{ name: "", stage: "Vendido" }],
    [PROJECTS_LIST.velia]: [{ name: "", stage: "Vendido" }],
};

export const PROJECT_FLATS = {
    [PROJECTS_LIST.ativa]:{},
    [PROJECTS_LIST.épiqe]:{},
    [PROJECTS_LIST.muvin]:{},
    [PROJECTS_LIST.sente]:{},
    [PROJECTS_LIST.nesta]:{},
    [PROJECTS_LIST.momen]:{},
}
export const PROJECTS_DATA = {
    [PROJECTS_LIST.midgo]: {...PROJECTS_CRM.midgo, name: ucFirst(PROJECTS_LIST.momen), ubication: PROJECT_LOCATIONS.midgo, phases: PROJECT_PHASES.momen},
    [PROJECTS_LIST.momen]: {...PROJECTS_CRM.momen, name: ucFirst(PROJECTS_LIST.momen), ubication: PROJECT_LOCATIONS.momen, phases: PROJECT_PHASES.momen},
    [PROJECTS_LIST.ativa]: {...PROJECTS_CRM.ativa, name: ucFirst(PROJECTS_LIST.ativa), ubication: PROJECT_LOCATIONS.ativa, phases: PROJECT_PHASES.ativa},
    [PROJECTS_LIST.épiqe]: {...PROJECTS_CRM.épiqe, name: ucFirst(PROJECTS_LIST.épiqe), ubication: PROJECT_LOCATIONS.épiqe, phases: PROJECT_PHASES.épiqe},
    [PROJECTS_LIST.muvin]: {...PROJECTS_CRM.muvin, name: ucFirst(PROJECTS_LIST.muvin), ubication: PROJECT_LOCATIONS.muvin, phases: PROJECT_PHASES.muvin},
    [PROJECTS_LIST.nesta]: {...PROJECTS_CRM.nesta, name: ucFirst(PROJECTS_LIST.nesta), ubication: PROJECT_LOCATIONS.nesta, phases: PROJECT_PHASES.nesta},
    [PROJECTS_LIST.sente]: {...PROJECTS_CRM.sente, name: ucFirst(PROJECTS_LIST.sente), ubication: PROJECT_LOCATIONS.sente, phases: PROJECT_PHASES.sente},
    [PROJECTS_LIST.duplo]: {...PROJECTS_CRM.duplo, name: ucFirst(PROJECTS_LIST.duplo), ubication: PROJECT_LOCATIONS.duplo, phases: []},
    [PROJECTS_LIST.prana]: {...PROJECTS_CRM.prana, name: ucFirst(PROJECTS_LIST.prana), ubication: PROJECT_LOCATIONS.prana, phases: []},
    [PROJECTS_LIST.velia]: {...PROJECTS_CRM.velia, name: ucFirst(PROJECTS_LIST.velia), ubication: PROJECT_LOCATIONS.velia, phases: []},
}

export const PROJECTS = [
    {...PROJECTS_CRM.midgo, name: ucFirst(PROJECTS_LIST.midgo), ubication: PROJECT_LOCATIONS.midgo, phases: PROJECT_PHASES.midgo},
    {...PROJECTS_CRM.momen, name: ucFirst(PROJECTS_LIST.momen), ubication: PROJECT_LOCATIONS.momen, phases: PROJECT_PHASES.momen},
    {...PROJECTS_CRM.ativa, name: ucFirst(PROJECTS_LIST.ativa), ubication: PROJECT_LOCATIONS.ativa, phases: PROJECT_PHASES.ativa, flat_types: PROJECT_FLATS.ativa.flat_types},
    {...PROJECTS_CRM.épiqe, name: ucFirst(PROJECTS_LIST.épiqe), ubication: PROJECT_LOCATIONS.épiqe, phases: PROJECT_PHASES.épiqe, flat_types: PROJECT_FLATS.épiqe.flat_types},
    {...PROJECTS_CRM.muvin, name: ucFirst(PROJECTS_LIST.muvin), ubication: PROJECT_LOCATIONS.muvin, phases: PROJECT_PHASES.muvin, flat_types: PROJECT_FLATS.muvin.flat_types},
    {...PROJECTS_CRM.nesta, name: ucFirst(PROJECTS_LIST.nesta), ubication: PROJECT_LOCATIONS.nesta, phases: PROJECT_PHASES.nesta, flat_types: PROJECT_FLATS.nesta.flat_types},
    {...PROJECTS_CRM.sente, name: ucFirst(PROJECTS_LIST.sente), ubication: PROJECT_LOCATIONS.sente, phases: PROJECT_PHASES.sente, flat_types: PROJECT_FLATS.sente.flat_types},
    {...PROJECTS_CRM.duplo, name: ucFirst(PROJECTS_LIST.duplo), ubication: PROJECT_LOCATIONS.duplo, phases: PROJECT_PHASES.duplo},
    {...PROJECTS_CRM.prana, name: ucFirst(PROJECTS_LIST.prana), ubication: PROJECT_LOCATIONS.prana, phases: PROJECT_PHASES.prana},
    {...PROJECTS_CRM.velia, name: ucFirst(PROJECTS_LIST.velia), ubication: PROJECT_LOCATIONS.velia, phases: PROJECT_PHASES.velia}
].map(p => new Project(p))

// console.log(PROJECTSS)